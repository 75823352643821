import achievementData from '../../app/data/achievements.json'
import gameData from '../../app/data/games.json'

export function fetchUserAchievements () {
  // TODO return saved achievements (see patchUserAchievement, patchUserGame, patchUserVideoGroup, patchUserContent)
  return [
    ...achievementData.map((achievement) => ({
      ...achievement,
      level: Math.round(Math.random() * 3)
    })),
    ...gameData.map((game) => ({
      ...game,
      type: 'game',
      icon: '/achievements/other/game.png',
      level: Math.round(Math.random() * 3)
    }))
  ]
}

export function patchUserAchievement (body) {
  // TODO simulate the achievement and save somewhere
  return { ok: true, achievements: [] }
}

export function patchUserGame (body) {
  // TODO simulate the achievement and save somewhere
  return { ok: true, achievements: [] }
}

export function patchUserVideoGroup (body) {
  // TODO simulate the achievement and save somewhere
  return { ok: true, achievements: [] }
}

export function patchUserContent (body) {
  // TODO simulate the achievement and save somewhere
  return { ok: true, achievements: [] }
}

export function fetchUserOrders () {
  // TODO return saved orders (see patchUserOrder)
  return []
}

export function patchUserOrder (body) {
  // TODO simulate the order and save somewhere
  return { ok: true, user_orders: [] }
}
