import { useEffect } from 'react'

import version from '../../public/version.json'

import styles from './styles.module.scss'

export default function AppVersion() {
  useEffect(() => console.log(version))

  return <div className={styles.version}>Version: {version.version}</div>
}
