import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useRouter } from 'next/router'

import LoadingAnimation from '../loading-animation'
import { stopEngine } from '../../app/features/engine/engineSlice'

import styles from './styles.module.scss'

export default function Loading () {
  const dispatch = useDispatch()
  const router = useRouter()
  const [showLight, setShowLight] = useState(false)

  async function goToMenu () {
    await dispatch(stopEngine())
    await router.push('/menu')
  }

  useEffect(() => {
    const timeout = setTimeout(() => setShowLight(true), 5000)
    return () => clearTimeout(timeout)
  })

  return <div className={styles.center}>
    {showLight && <div className={styles.light} onClick={goToMenu}>
      <p>Está com pressa?</p>
      <a>Tente a versão light</a>
    </div>}
    <LoadingAnimation />
    <p>Carregando...</p>
  </div>
}
