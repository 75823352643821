import Link from 'next/link'
import { useRouter } from 'next/router'
import { useSelector } from 'react-redux'

import { selectStarted } from '../../app/features/engine/engineSlice'

import styles from './styles.module.scss'

export default function Background () {
  const started = useSelector(selectStarted)
  const router = useRouter()

  return !started && router.route !== '/login' && router.route !== '/' && (
    <Link href='/menu'>
      <a className={styles.logo}>
        <img src='/blip-con-logo.png' alt='Blip Con' height={50} />
      </a>
    </Link>
  )
}
