import { useEffect } from 'react'
import Link from 'next/link'

import { playSound } from '../../app/lib/audio'

import styles from './styles.module.scss'

export default function VideoToast ({ video, onClick }) {
  useEffect(() => playSound('general/achievement'))

  return (
    <div className={styles.video}>
      <div className={styles.icon}>►</div>
      <div className={styles.text}>
        <div>Seu video &quot;{video?.title}&quot; vai começar logo</div>
        <Link href={`/video/${video.video_key}`}><a onClick={onClick}>Assistir</a></Link>
      </div>
    </div>
  )
}
