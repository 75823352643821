import React from 'react'
import Router from 'next/router'
import Head from 'next/head'
import NProgress from 'nprogress'
import { Provider } from 'react-redux'
import { Toaster } from 'react-hot-toast'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'

import GodotWrapper from '../components/godot-wrapper'
import GodotBridge from '../components/godot-bridge'
import AppVersion from '../components/app-version'
import VideoNotifications from '../components/video-notifications'
import Background from '../components/background'
import CornerButtons from '../components/corner-buttons'
import { store } from '../app/store'

import 'swiper/scss'
import 'swiper/scss/navigation'
import 'swiper/scss/pagination'

import '../styles/nprogress.css'
import '../styles/globals.scss'

NProgress.configure({ showSpinner: true })

Router.onRouteChangeStart = () => NProgress.start()
Router.onRouteChangeComplete = () => NProgress.done()
Router.onRouteChangeError = () => NProgress.done()

function SafeHydrate ({ children }) {
  return (
    <div suppressHydrationWarning>
      {typeof window === 'undefined' ? null : children}
    </div>
  )
}

function MyApp ({ Component, pageProps }) {
  const handle = useFullScreenHandle()

  return (
    <SafeHydrate>
      <Provider store={store}>
        <FullScreen handle={handle}>
          <Head>
            <title>Blip Con</title>
            <meta name='viewport'
                  content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0' />
          </Head>

          <GodotWrapper />
          <GodotBridge />
          <Background />
          <Component {...pageProps} fullScreenHandle={handle} />
          <Toaster />
          <AppVersion />
          <VideoNotifications />
          <CornerButtons fullScreenHandle={handle} />
        </FullScreen>
      </Provider>
    </SafeHydrate>
  )
}

export default MyApp
