import { useCallback, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { useDispatch, useSelector } from 'react-redux'

import { achievementToast } from '../../app/lib/toast'
import { patchUserAchievement, patchUserGame } from '../../app/lib/api'
import { selectUserData } from '../../app/features/user/userSlice'
import { startedEngine, updateCheckpoints } from '../../app/features/engine/engineSlice'
import { panSound, pitchSound, playSound, stopSound } from '../../app/lib/audio'

export function completeCheckpoint (type, number) {
  window?.godot_complete_checkpoint_callback?.(type, number)
}

export default function GodotBridge () {
  const user = useSelector(selectUserData)
  const router = useRouter()
  const dispatch = useDispatch()
  const [hasUpdatesCallback, setHasHasUpdatesCallback] = useState(false)

  const on_started = useCallback(async () => {
    console.log('GodotBridge#on_started')
    setHasHasUpdatesCallback(true)
    await dispatch(startedEngine())
  }, [dispatch, setHasHasUpdatesCallback])

  const navigate = useCallback(async (route) => {
    console.log('GodotBridge: Navigate to:', route)
    router.push(route).then()
  }, [router])

  const complete_achievement = useCallback(async (achievement_key) => {
    console.log('GodotBridge#complete_achievement', achievement_key)
    const response = patchUserAchievement({ achievement_key })
    achievementToast(response)
  }, [])

  const complete_level = useCallback(async (game_key, level) => {
    console.log('GodotBridge#complete_level', game_key, level)
    const response = patchUserGame({ game_key, level })
    achievementToast(response)
  }, [])

  const get_user = useCallback(() => user, [user])

  const on_checkpoints_updated = useCallback(async (type, current) => {
    console.log('GodotBridge#update_checkpoints', type, current)
    dispatch(updateCheckpoints([type, current]))
  }, [dispatch])

  const add_breadcrumb = useCallback(async (name) => {
    console.log('GodotBridge#add_breadcrumb', name)
  }, [])

  useEffect(() => {
      let lastRoute = null
      const onRouteChangeStart = (url) => {
        if (window?.godot_navigate_callback) {
          console.log('GodotBridge: Notifying godot about page navigation')

          let newRoute = null
          if (/\/content.*/.test(url)) {
            newRoute = '/contents'
          } else if (/\/achievements.*/.test(url)) {
            newRoute = '/achievements'
          } else if (/\/leaderboard.*/.test(url)) {
            newRoute = '/achievements'
          } else if (/\/video.*/.test(url)) {
            newRoute = '/videos'
          } else if (/\/store.*/.test(url)) {
            newRoute = '/store'
          } else if (url === '/about') {
            newRoute = '/about'
          } else if (url === '/faq') {
            newRoute = '/faq'
          } else if (url === '/' || url === '/menu') {
            newRoute = '/'
          } else {
            console.log('Unknown route:', url)
            return
          }

          if (lastRoute !== newRoute) {
            window.godot_navigate_callback(newRoute)
            lastRoute = newRoute
          }
        }
      }

      router.events.on('routeChangeStart', onRouteChangeStart)

      return () => {
        router.events.off('routeChangeStart', onRouteChangeStart)
      }
    }, [router]
  )

  useEffect(() => {
    window.godot_bridge = {
      on_started,
      navigate,
      complete_achievement,
      complete_level,
      get_user,
      on_checkpoints_updated,
      add_breadcrumb,
      play_sound: playSound,
      stop_sound: stopSound,
      pan_sound: panSound,
      pitch_sound: pitchSound
    }
    return () => delete window.godot_bridge
  })

  useEffect(() => {
    if (window?.godot_updates_callback) {
      console.log('GodotBridge: Notifying godot about updates')
      window.godot_updates_callback()
    }
  }, [hasUpdatesCallback, user])

  return null
}
