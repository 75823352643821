import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import userReducer from './features/user/userSlice'
import videosReducer from './features/videos/videosSlice'
import engineReducer from './features/engine/engineSlice'

const appReducer = combineReducers({
  user: userReducer,
  videos: videosReducer,
  engine: engineReducer
})

const initialState = appReducer({}, {})

const rootReducer = (state, action) => {
  return appReducer(action.type === 'user/logout' ? initialState : state, action)
}

const config = {
  key: 'root',
  debug: true,
  blacklist: ['engine'],
  storage
}

const persistedReducer = persistReducer(config, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  devTools: true,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: ['persist/PERSIST']
    }
  })
})

export const persistor = persistStore(store)
