import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  notifications: {},
}

export const videosSlice = createSlice({
  name: 'videos',

  initialState,

  reducers: {
    toggleNotification: (state, action) => {
      const video = action.payload
      if (!!state.notifications[video.video_key]) {
        delete state.notifications[video.video_key]
      } else {
        state.notifications[video.video_key] = video
      }
    }
  }
})

export const { toggleNotification } = videosSlice.actions

export const selectHasNotification = (state, video_key) => !!state.videos.notifications[video_key]

export default videosSlice.reducer
