import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import dayjs from 'dayjs'

import { store } from '../../app/store'
import { toggleNotification } from '../../app/features/videos/videosSlice'
import { videoToast } from '../../app/lib/toast'

export default function VideoNotifications () {
  const dispatch = useDispatch()

  useEffect(() => {
    const timer = setInterval(() => {
      const notifications = store.getState().videos.notifications
      Object.entries(notifications).forEach(([video_key, video]) => {
        if (!video.video_key || dayjs().add(5, 'minutes').isAfter(video.start_date)) {
          videoToast(video)
          dispatch(toggleNotification({ video_key }))
        }
      })
    }, 15 * 1000)

    return () => clearInterval(timer)
  })

  return null
}
