import ceach from 'concurrent-each'

export const CONFERENCE_DAYS = [
  { day: 26, slug: '2021-10-26', date: '26 de Outubro' },
  { day: 27, slug: '2021-10-27', date: '27 de Outubro' },
  { day: 28, slug: '2021-10-28', date: '28 de Outubro' }
]

export const CONFERENCE_DAYS_BY_SLUG = CONFERENCE_DAYS.reduce((acc, day) => {
  acc[day.slug] = day
  return acc
}, {})

export const CONFERENCE_DAYS_BY_DAY = CONFERENCE_DAYS.reduce((acc, day) => {
  acc[day.day] = day
  return acc
}, {})

export const MAX_POINTS = 190000

export const POINTS_PER_VIDEO_TYPE = {
  keynote: 3000,
  internal: 2000,
  external: 3000,
  panel: 3000,
  attraction: 750,
  test: 1000000
}

export const POINTS_PER_CONTENT_TYPE = {
  video: 1750,
  link: 1250,
  document: 1250
}

export const LEVEL_NAMES = [
  '',
  'Iniciante I',
  'Iniciante II',
  'Elite I',
  'Elite II',
  'Pro I',
  'Pro II',
  'Pro III',
  'Mestre I',
  'Mestre II',
  'Lenda I',
  'Lenda II'
]

export function groupBy (array, property) {
  return array.reduce((acc, item) => {
    const key = item[property]
    if (!acc[key]) acc[key] = []
    acc[key].push(item)
    return acc
  }, {})
}

export async function asyncEach (collection, func) {
  return await Promise.all(await ceach.map(collection, func))
}

export function userResponseData (user) {
  return { earned_points: user.earned_points, level: user.level }
}

export async function goBack (router, target = null, browser = false) {
  if (target && !document.referrer.endsWith(target)) {
    await router.push(target)
  } else if (browser) {
    await router.back()
  } else {
    if (window?.godot_navigate_callback) {
      router.push('/')
    } else {
      router.push('/menu')
    }
  }
}
