import { createSlice } from '@reduxjs/toolkit'
import { nanoid } from 'nanoid'
import faker from 'faker/locale/pt_BR'

import { MAX_POINTS } from '../../lib/utils'

const earned_points = 20000 + Math.round(Math.random() * 100000)
const spent_points = 10000 + Math.round(Math.random() * 10000)
const level = Math.round(11 * earned_points / MAX_POINTS)

export const initialState = {
  _id: nanoid(),
  nickname: faker.internet.userName(),
  earned_points,
  spent_points,
  level
}

export const userSlice = createSlice({
  name: 'user',

  initialState,

  reducers: {
    earnPoints: (state, action) => {
      state.earned_points += action.payload
      state.level = Math.round(11 * state.earned_points / MAX_POINTS)
    },

    spendPoints: (state, action) => {
      state.da.spent_points += action.payload
    }
  }
})

export const { earnPoints, spendPoints } = userSlice.actions

export const selectUserData = (state) => state.user

export default userSlice.reducer
