import { Howl } from 'howler'

import audioList from '../../public/audio/list.json'

Howler.html5PoolSize = 30

const files = Object.fromEntries(audioList.map((file) => [file, `/audio/${file}.ogg`]))
const sounds = Object.fromEntries(Object.entries(files).map(([key, value]) => [key, new Howl({ src: [value] })]))

sounds['menu/rocket-loop'].loop(true)

export function playSound (id, volume = 1.0) {
  if (id in sounds) {
    sounds[id].volume(volume)
    sounds[id].stereo(0.0)
    sounds[id].rate(1.0)
    sounds[id].play()
  }
}

export function stopSound (id) {
  if (id in sounds) {
    sounds[id].stop()
  }
}

export function panSound (id, stereo = 0.0, volume = null) {
  if (id in sounds) {
    if (volume) {
      sounds[id].volume(volume)
    }
    sounds[id].stereo(stereo)
  }
}

export function pitchSound (id, rate = 1.0) {
  if (id in sounds) {
    sounds[id].rate(rate)
  }
}

if (typeof window != 'undefined') {
  window.playSound = playSound
}
