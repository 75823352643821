import toast from 'react-hot-toast'

import AchievementToast from '../../components/achievement-toast'
import VideoToast from '../../components/video-toast'

export function errorToast (message) {
  toast(message ?? 'Ocorreu um erro, por favor tente novamente dentro de alguns instantes')
}

export function achievementToast (body) {
  if (body.achievements) {
    body.achievements.forEach((achievement) => achievementToast({ achievement }))
  }

  if (body.achievement) {
    toast.custom(<AchievementToast {...body} />)
  }
}

export function videoToast (video) {
  if (video) {
    const toastId = toast.custom(
      <VideoToast video={video} onClick={() => toast.dismiss(toastId)} />,
      { duration: 20000 }
    )
  }
}
